@import "https://fonts.googleapis.com/css2?family=Open+Sans&family=Volkhov:wght@400;700&display=swap";
* {
  box-sizing: border-box;
}

a {
  color: var(--textTheme);
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

br.desktop {
  display: none;
}

@media (min-width: 600px) {
  br.desktop {
    display: inline;
  }
}

.prose {
  font-size: 1.5rem;
  line-height: 1.5;
}

html {
  height: 100%;
  --bg: white;
  --theme: white;
  --text: #222f3e;
  --textReversed: #222f3e;
  --textTheme: #00d2d3;
}

body {
  background: var(--bg);
  color: var(--text);
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: Open Sans;
  transition: background .1s ease-in-out, color .1s ease-in-out;
  display: flex;
  position: relative;
}

body ::selection {
  background: var(--textTheme);
  color: var(--bg);
}

.seperator {
  width: 100%;
  max-width: 900px;
  align-items: center;
  margin: 30px auto;
  padding: 0 20px;
  display: flex;
}

.seperator .scissors {
  font-size: 1.5rem;
  transform: rotate(-90deg);
}

.seperator .spacer {
  padding: 0 5px;
}

.seperator .line {
  border-bottom: 2px dashed #000;
  flex-grow: 1;
}

.seperator.reversed {
  flex-direction: row-reverse;
}

.seperator.reversed .scissors {
  transform: rotate(90deg);
}

header {
  flex-direction: column;
  display: flex;
}

.header__hero {
  background: var(--theme);
  color: var(--textReversed);
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  transition: background .1s ease-in-out, color .1s ease-in-out;
  display: flex;
  position: relative;
}

@media (min-width: 600px) {
  .header__hero {
    align-items: center;
    padding: 20px;
  }
}

.header__hero h1 {
  text-align: center;
  margin: 0 0 20px;
  font-family: Volkhov;
  font-size: 7rem;
  line-height: 1;
}

.header__hero h2 {
  font-family: monospace;
}

.header__hero .header__title {
  min-height: 0;
  margin-top: 2rem;
  position: relative;
}

@media (min-width: 600px) {
  .header__hero .header__title {
    min-height: 0;
    padding: 0;
  }
}

.header__hero .name {
  color: var(--textTheme);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
  transition: background .1s ease-in-out, color .1s ease-in-out;
}

.header__hero .name-hover {
  opacity: .3;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

@media (min-width: 600px) {
  .header__hero .name-hover {
    justify-content: center;
  }
}

.header__hero .header__quote {
  opacity: .5;
  max-width: 75%;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2em;
  font-style: italic;
  display: flex;
}

@media (min-width: 600px) {
  .header__hero .header__quote {
    justify-content: center;
  }
}

.header__hero .fullstop {
  opacity: .2;
}

.header__profile {
  cursor: pointer;
  max-width: 100vw;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  margin: 25px;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.header__profile .base {
  filter: brightness(80%) contrast();
  margin: 0;
  padding: 0;
  display: block;
}

.header__profile .duotone {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header__profile .duotone--lo {
  background: var(--text);
  mix-blend-mode: lighten;
  background: var(--textTheme);
  mix-blend-mode: darken;
  transition: background .1s ease-in-out, color .1s ease-in-out;
}

.sticky-header {
  background: var(--theme);
  color: var(--textReversed);
  z-index: 1;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  transition: background .1s ease-in-out, color .1s ease-in-out;
  display: flex;
  position: sticky;
  top: 0;
}

.sticky-header a {
  opacity: 1;
  border-radius: 5px;
  margin: 0 10px;
  padding: 4px 5px 1px;
  transition: opacity .2s ease-in-out;
  display: block;
}

@media (min-width: 600px) {
  .sticky-header a {
    margin: 0 20px;
    padding: 10px 10px 7px;
  }
}

.sticky-header a:hover {
  opacity: .75;
}

.sticky-header svg {
  fill: var(--textReversed);
  color: var(--textReversed);
  width: 30px;
  height: 30px;
}

.about {
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
  padding: 70px 20px;
  font-size: 1.75rem;
}

@media (min-width: 600px) {
  .about {
    padding: 70px 50px;
    font-size: 2rem;
  }
}

.about-description {
  opacity: .6;
  font-size: .8em;
  font-style: italic;
}

section {
  width: 100%;
  margin: 0 auto;
}

section .project {
  max-width: 900px;
  margin: auto;
}

section h2 {
  text-align: center;
  background: var(--textTheme);
  color: #fff;
  margin: 50px 0 100px;
  padding: 20px 0;
  font-family: Volkhov;
  font-size: 2.5rem;
}

@media (min-width: 600px) {
  section h2 {
    font-size: 3rem;
  }
}

.image-seperator {
  height: 300px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  border: none;
  margin: 40px 0;
}

@media (min-width: 600px) {
  .image-seperator {
    background-attachment: fixed;
  }
}

.skills {
  justify-content: center;
  display: flex;
}

.skills .hidden {
  display: none;
}

.project {
  color: var(--textTheme);
  color: var(--text);
  grid-gap: 20px;
  border: 1px solid #fff3;
  border-radius: 10px;
  grid: "img" 1fr
        "tags"
        "title"
        "desc"
        "footer"
        / 1fr;
  padding: 2rem;
  font-weight: normal;
  text-decoration: none;
  transition: border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  display: grid;
}

.project:hover {
  text-decoration: none;
}

.project .nda {
  filter: blur(8px);
}

.project .center {
  text-align: center;
}

.project .tags {
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.project .tags .tag {
  text-transform: uppercase;
  letter-spacing: .1rem;
  background: var(--textTheme);
  color: var(--bg);
  border-radius: 100vh;
  margin-bottom: 6px;
  margin-right: 10px;
  padding: 2px 12px;
  font-size: .8rem;
  font-weight: bold;
}

.project .title {
  text-align: center;
  grid-area: title;
  font-family: Volkhov;
  font-size: 2rem;
}

.project .title .duration {
  opacity: .3;
}

.project .name {
  text-decoration-color: var(--textTheme);
  color: var(--textTheme);
  font-weight: bold;
}

.project .dash {
  height: 2px;
  width: 10px;
  background: var(--text);
  vertical-align: middle;
  display: inline-block;
}

.project .footer {
  text-align: center;
}

.project .image {
  height: 100%;
  grid-area: img;
  overflow: hidden;
}

.project .image img, .project .image video {
  height: 100%;
  width: 100%;
  max-height: 600px;
  object-position: center center;
  object-fit: cover;
  transition: transform .3s ease-in-out;
  transform: scale(1);
}

.project .description {
  grid-area: desc;
  justify-self: center;
  margin: 10px 0;
  font-size: .9em;
}

@media (min-width: 600px) {
  .project .description {
    font-size: 1em;
  }
}

.project .description .aside {
  opacity: .3;
}

.project .footer {
  grid-area: footer;
}

.project .footer .aside {
  opacity: .3;
}

@media (min-width: 600px) {
  .project {
    grid-gap: 5px 40px;
    grid: "img tags"
          "img title"
          "img desc" 1fr
          "footer footer"
          / auto 1fr;
    padding: 30px;
  }

  .project.reversed {
    grid: "tags img"
          "title img"
          "desc img" 1fr
          "footer footer"
          / 1fr auto;
  }

  .project .center {
    text-align: center;
  }

  .project .image {
    max-width: 300px;
  }

  .project:hover {
    border: 1px solid #f4f4f4;
    text-decoration: none;
    box-shadow: 0 4px 10px -3px #0003;
  }

  .project:hover .image img {
    transform: scale(1.05);
  }
}

.end {
  text-align: center;
}

.end p {
  padding: 0 10vw;
}

.end p a {
  align-items: center;
  display: inline-flex;
}

.end p span {
  opacity: .5;
}

.end p svg {
  width: 20px;
  height: 20px;
  color: var(--textTheme);
  fill: var(--textTheme);
  margin-right: 6px;
  padding-top: 2px;
}

footer {
  text-align: center;
  padding: 50px;
}

footer .copyright {
  margin-bottom: 2px;
}

footer .❤ {
  color: #ee5253;
  vertical-align: middle;
  font-size: 1.2rem;
  display: inline-block;
}

footer a {
  position: relative;
}

footer a:after {
  content: "";
  width: 100%;
  height: 2px;
  opacity: 0;
  transition: opacity .2s;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
}

footer a:hover:after {
  opacity: 1;
}

.jakbarnes {
  background-image: linear-gradient(to right, #00d2ff, #2b6bcc);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: #0000;
  font-weight: bold;
}

.jakbarnes:after {
  background: linear-gradient(to right, #00d2ff, #2b6bcc);
}

.zonalhaz {
  background-image: linear-gradient(to right, #00d2d3, #10ac84);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: #0000;
}

.zonalhaz:after {
  background: linear-gradient(to right, #00d2d3, #10ac84);
}

.cesque {
  background-image: linear-gradient(to right, #ff9f43, #ee5253);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: #0000;
}

.cesque:after {
  background: linear-gradient(to right, #ff9f43, #ee5253);
}

.coins {
  background-image: linear-gradient(to right, #f368e0, #a55eea, #5f27cd);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: #0000;
}

.coins:after {
  background: linear-gradient(to right, #f368e0, #a55eea, #5f27cd);
}

/*# sourceMappingURL=index.b79b7d3d.css.map */
